<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ $t('TEMPLATES.TITLE_NEW') }}</h5>
    </div>
  </template>
  <template #toolbar>
    <div class="example-tools justify-content-center">
      <router-link :to="{ name: 'editCompany', params: { id: $route.params.id } }" class="btn btn-secondary font-weight-bold btn-sm">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
        </span>
        {{ $t('BASE.BACK') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <b-container fluid class="p-0">
        <b-row>
          <b-col cols="12" md="12" offset-md="0">
            <b-form-group label-for="input-1" label-align-md="right" class="mb-2">
              <SelectInfinityScroll
                id="select-1"
                v-model="$v.form.name.$model"
                :state="validateState('name') === false"
                :items="names"
                :multiple="false"
                @input="clearServerError('name')"
              />
            </b-form-group>
            <b-form-invalid-feedback id="input-1-feedback" :state="validateState('name')">
              <template v-if="serverErrors.name">{{ serverErrors.name[0] }}</template>
              <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.NAME') }) }}</template>
            </b-form-invalid-feedback>
            <b-form-group label-align-md="right" class="pt-4 mb-2">
              <ace
                @input="clearServerError('template')"
                :options="{printMargin:140}"
                v-model="$v.form.template.$model"
                @init="editorInit"
                lang="twig"
                theme="dracula"
                width="100%"
                height="600"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="validateState('template')">
              <template v-if="serverErrors.template">{{ serverErrors.template[0] }}</template>
              <template v-if="!$v.form.template.required">{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.TEMPLATE') }) }}</template>
            </b-form-invalid-feedback>
            <div class="text-right">
              <b-button :disabled="loading || $v.form.$anyError" type="submit" variant="primary" size="sm">
                <b-spinner v-if="loading" small variant="light" />
                {{ $t('FORM.SUBMIT') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </template>
</KTCard>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import serverVuelidate from '@/mixins/serverVuelidate'
import Repo from '@/core/repository/admin/templatesRepository'
import { TIMEOUT_REDIRECT } from '@/constants/base'
import SelectInfinityScroll from '@/components/forms-items/SelectInfinityScroll'
import KTCard from '../../../../../content/Card'

export default {
  name: 'CompanyTemplatesCreate',
  components: {
    KTCard,
    Ace: require('vue2-ace-editor'),
    SelectInfinityScroll,
  },
  mixins: [
    validationMixin,
    serverVuelidate,
  ],
  data() {
    return {
      loading: false,
      form: {
        name: '',
        template: null,
        company: this.$route.params.id,
      },
      names: [
        {
          uuid: 'order_invoice',
          name: this.$t('TEMPLATES.ORDER_INVOICE'),
        },
        {
          uuid: 'order_invoice_for_cut',
          name: this.$t('TEMPLATES.ORDER_INVOICE_FOR_CUT'),
        },
        {
          uuid: 'phone_confirmation',
          name: this.$t('TEMPLATES.PHONE_CONFIRMATION'),
        },
        {
          uuid: 'order_status_in_progress',
          name: this.$t('TEMPLATES.ORDER_STATUS_IN_PROGRESS'),
        },
        {
          uuid: 'order_status_complete',
          name: this.$t('TEMPLATES.ORDER_STATUS_COMPLETE'),
        },
        {
          uuid: 'order_status_cancel',
          name: this.$t('TEMPLATES.ORDER_STATUS_CANCEL'),
        },
      ],
      validations: {
        form: {
          name: { required },
          template: { required },
        },
      },
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('TEMPLATES.TITLE_NEW') }])
    this.form.name = this.names[0].uuid
  },
  methods: {
    editorInit(editor) {
      editor.container.style.fontSize = '14px'
      editor.container.style.borderRadius = '3px'
      editor.setShowPrintMargin('160px')
      // eslint-disable-next-line import/no-extraneous-dependencies
      require('brace/ext/language_tools')
      // eslint-disable-next-line import/no-extraneous-dependencies
      require('brace/mode/html')
      // eslint-disable-next-line import/no-extraneous-dependencies
      require('brace/mode/twig')
      // eslint-disable-next-line import/no-extraneous-dependencies
      require('brace/theme/dracula')
      // eslint-disable-next-line import/no-extraneous-dependencies
      require('brace/snippets/javascript')
    },
    resetForm() {
      this.form = {
        name: '',
        template: '',
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.clearServerErrors()
      this.loading = true
      const obj = { ...this.form }
      obj.name = obj.name.uuid
      Repo.post(obj).then(() => {
        this.$bvToast.toast(this.$t('TOAST.CREATED'), {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 2000,
          solid: true,
        })
        this.resetForm()
        setTimeout(() => this.$router.push({ name: 'companyTemplatesIndex' }), TIMEOUT_REDIRECT)
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.seServerErrors(err.response.data)
      })
    },
  },
}
</script>

<style scoped>

</style>
